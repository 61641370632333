<template>
    <div>
        <!-- <Sidebar /> -->
        <HeaderPage />

        <div>
            <!-- <v-card
                class="mx-auto justify-center text-center"
                style="position: relative"
            > -->
                <v-img
                tile
                style=""
                class="d-flex align-center justify-center mt-5 ml-2"
                height="200px"
                :src="tajimi_img"
                contain
                ></v-img>
            <!-- </v-card> -->
            <v-card class="pb-7" flat>
                <div class="pt-5 ps-3">
                    <p class="ps-3 text-title">Tajimingo</p>
                </div>
            </v-card>

            <div class="px-3">
                <table class="table table-bordered border-primary" style="padding: 0px">
                <tbody>
                    <tr>
                    <th>運営責任者</th>
                    <!-- <td>福田慎吾</td> -->
                    <td>{{ platformInfo.ops_manager }}</td>
                    </tr>
                    <tr>
                    <th class="text-wrap">住所</th>
                    <!-- <td>岐阜県多治見市</td> -->
                    <td>{{ platformInfo.location }}</td>
                    </tr>
                    <tr>
                    <th>電話番号</th>
                    <!-- <td>0572-22-1252</td> -->
                    <td>{{ platformInfo.phone }}</td>
                    </tr>
                    <!-- <tr> -->
                    <!-- <th>FAX</th> -->
                    <!-- <td>番号</td> -->
                    <!-- <td>{{ platformInfo.fax }}</td> -->
                    <!-- </tr> -->
                    <tr>
                    <th>メールアドレス</th>
                    <!-- <td>support@ tajimingo.com</td> -->
                    <!-- <td>{{ platformInfo.email }}</td> -->
                        <td>
                            <a :href="platformInfo.email" target="_blank">
                                {{ platformInfo.email }}
                            </a>
                        </td>
                    </tr>
                    <tr>
                    <th>URL</th>
                    <td>
                        <a :href="platformInfo.url" target="_blank">
                            {{ platformInfo.url }}
                        </a>
                    </td>
                    </tr>
                    <tr>
                    <th>商品以外の必要代金</th>
                    <!-- <td>各ショップのページをご参照願います。</td> -->
                    <td>{{ platformInfo.necessary_price }}</td>
                    </tr>
                    <tr>
                    <th>注文方法</th>
                    <!-- <td>各ショップのページをご参照願います。</td> -->
                    <td>{{ platformInfo.how_to_order }}</td>
                    </tr>
                    <tr>
                    <th>支払方法</th>
                    <!-- <td>
                        各ショップのページをご参照願います。
                    </td> -->
                    <td>{{ platformInfo.payment_method }}</td>
                    </tr>
                    <tr>
                    <th>支払期限</th>
                    <!-- <td>各ショップのページをご参照願います。</td> -->
                    <td>{{ platformInfo.due_date_payment }}</td>
                    </tr>
                    <tr>
                    <th>引渡し時期</th>
                    <!-- <td>各ショップのページをご参照願います。</td> -->
                    <td>{{ platformInfo.delivery_time }}</td>
                    </tr>
                    <tr>
                    <th>返品</th>
                    <!-- <td>交換について   各ショップのページをご参照願います。</td> -->
                    <td>{{ platformInfo.about_goods }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <div class="mt-5 d-flex justify-center">
            <span style="font-size:14px;color:#666;">令和2年度第3次補正 事業再構築補助金により作成</span>
        </div>
        <div class="mt-5 d-flex justify-center">
            <router-link to="/" style="text-decoration: none">
                <v-btn
                depressed
                color="#ff0090"
                style="color: white; padding: 0px;text-transform: none;"
                width="180px"
                height="35px"
                class="p-0"
                >
                {{ $t("message.back-to-top") }}
                </v-btn>
            </router-link>
        </div>
        <div style="margin-bottom: 8rem">
            <CategoryFooter />
        </div>
        <Sidebar />
    </div>
</template>

<script>
import Sidebar from '../../components/developmentv2/Sidebar.vue'
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import CategoryFooter from '../../components/CategoryFooter.vue'
export default {
    name: "TajimiInfo",
    components: {
        Sidebar,
        CategoryFooter,
        HeaderPage
    },
    data() {
        return {
            tajimi_img: require('../../assets/logo_wbase.png')
        }
    },
    computed: {
        platformInfo() {
            return this.$store.state.about_module.platformInfo
        }
    },
    methods: {
        dispatchPlatformInfo() {
            this.$store.dispatch("about_module/fetchTajimiInfo")
        }
    },
    created() {
        this.dispatchPlatformInfo()
    }
}
</script>

<style>
.text-title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}
table {
  min-width: 95%;
  margin: auto;
  border: 1px solid #707070;
}
td {
  min-width: 40%;
  flex: 1;
}
tr {
  display: flex;
  flex-wrap: wrap;
}
tr + tr {
  border-top: 2px solid #707070;
  border-color: #707070;

  padding: 0px !important;
}
tr th {
  background-color: #e0e0e0;
  width: 33%;
  text-align: left;
  padding: 5px;
  /* text-overflow: w; */
}
tr td {
  padding: 5px !important;
}
</style>